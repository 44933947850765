import * as React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { StepOne } from './signup-steps/StepOne';
import { StepTwo } from './signup-steps/StepTwo';
import { StepThree } from './signup-steps/StepThree';
import { StepFour } from './signup-steps/StepFour';
import ReactGA from 'react-ga4';
import { useSearchParams, useNavigate } from 'react-router-dom';

export default function SignUp({step = 1}) {
  const [currentStep, setCurrentStep] = React.useState(step);
  const [email, setEmail] = React.useState("");
  const [signupCode, setSignupCode] = React.useState("");
  const [messageId, setMessageId] = React.useState("");
  const [name, setName] = React.useState("");
  const [initializedThroughSSO, setInitializedThroughSSO] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [provider, setProvider] = React.useState(null);
  const [requirePI, setRequirePI] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (currentStep === 1) {
      ReactGA.send({
        hitType: "pageview",
        path: "step-1"
      });
    }
    const email = searchParams.get('email');
    const name = searchParams.get('name');
    const signupVerificationCode = searchParams.get('signupCode');
    const provider = searchParams.get('provider');
    const requirePIStr = searchParams.get('requirePI');
    const requirePI = !requirePIStr || requirePIStr === 'true';
    if (email && signupVerificationCode && name && provider) {
      nextStepCallback(3, email, signupVerificationCode, null, name, true, provider, requirePI);
      navigate('/', { replace: true });
    }
  });

  const nextStepCallback = (step, email="", signupVerificationCode = "", messageId = "", name = "", initializedThroughSSO = false, provider = null, requirePI = true) => {
    ReactGA.send({
      hitType: "pageview",
      path: "step-"+step
    });
    setEmail(email);
    setName(name);
    setSignupCode(signupVerificationCode);
    setCurrentStep(step);
    setMessageId(messageId);    
    setInitializedThroughSSO(initializedThroughSSO);    
    setProvider(provider);
    setRequirePI(requirePI);
  }

  const headingFonts = [
    'Plus Jakarta Sans',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif'
  ].join(',');
  
  const textFonts = [
    'Inter',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif'
  ].join(',');

  const theme = createTheme({
    typography: {
      h1: {
        fontFamily: headingFonts,
      },
      h2: {
        fontFamily: headingFonts,
      },
      h3: {
        fontFamily: headingFonts,
      },
      h4: {
        fontFamily: headingFonts,
      },
      h5: {
        fontFamily: headingFonts,
      },
      h6: {
        fontFamily: headingFonts,
      },
      body1: {
        fontFamily: textFonts,
      },
      body2: {
        fontFamily: textFonts,
      },
      button: {
        fontFamily: textFonts,
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100%' }}>
        <Grid
          className='signup-bg-image'
          item
          sm={12}
          md={7}
          lg={8}
          sx={{
            backgroundImage: 'url(./bg.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            px: {
              xs: 2,
              md: 0
            },
            py: {
              xs: 4,
              md: 0
            },
            height: "auto"
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%' }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                maxWidth: {
                  xs: '100%',
                  md: '75%',
                  lg: '60%'
                }
              }}
            >
              <Grid item>
                <a href="https://keystrike.com" style={{ cursor: 'pointer' }} target='blank'>
                  <img
                    src="/ks-logo.png"
                    alt="keystrike-logo"
                    width={150}
                  />
                </a>
              </Grid>
              <Grid
                item
                sx={{
                  mt: 2
                }}
              >
                <Typography
                  component="h1"
                  variant="h4"
                  color="white"
                  fontWeight={600}
                  sx={{
                    fontSize: {
                      xs: 21,
                      sm: 32
                    }
                  }}
                >
                  Better security can't wait
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  color="#AAA"
                  sx={{
                    mt: 0.5
                  }}
                >
                  Sign up, register your organization, and join other forward-thinking and security aware organizations.
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  mt: 4
                }}
              >
                <Stack
                  direction="column" 
                  spacing={2}
                  sx={{
                    borderRadius: 3,
                    border: 1,
                    borderColor: '#2970FF',
                    background: 'rgba(99, 102, 241, 0.08)',
                    p: 3,
                  }}
                >
                  <Typography
                    variant="body2"
                    color="#F8F9FA"
                    sx={{
                      fontStyle: 'italic',
                      opacity: 0.8
                    }}
                  >
                    In about 20 minutes, I had Keystrike up and running. The deployment is simple, well thought out, with clear documentation. Now Keystrike helps us establish that commands are genuine and trustworthy by detecting lurking attackers and blocking when they inject themselves into active sessions. With the combination of powerful technology and ease of deployment, I highly recommend testing Keystrike.
                  </Typography>
                  <Stack
                    spacing={2}
                    direction='row'
                    alignItems='center'
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: 42,
                        height: 42,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#ffffff',
                        borderRadius: '50%'
                      }}
                    >
                      <img
                        src="/testimonial-logo.png"
                        alt="Global Water Resources logo"
                        width={28}
                      />
                    </Box>
                    <Typography
                      variant="caption"
                      color="#F8F9FA"
                      sx={{
                        opacity: 0.8,
                        textTransform: 'uppercase',
                        fontWeight: 600,
                        letterSpacing: 1
                      }}
                    >
                      Steven Brill, vp of IT operations and security
                      <br />
                      Global Water Resources
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Box>            
          </Grid>
        </Grid>
        <Grid 
          item 
          sm={12}
          md={5}
          lg={4}
          sx={{
            py: 4
          }}
        >
          {currentStep === 1 ? <StepOne nextStepCallback={nextStepCallback} /> : null}
          {currentStep === 2 ? <StepTwo nextStepCallback={nextStepCallback} email={email} /> : null}
          {currentStep === 3 ? <StepThree nextStepCallback={nextStepCallback} email={email} signupCode={signupCode} fullName={name} initializedThroughSSO={initializedThroughSSO} provider={provider} requirePI={requirePI}/> : null}
          {currentStep === 4 ? <StepFour email={email} messageId={messageId} /> : null}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}