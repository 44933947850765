const environments = {
    development: {
      apiUrl: 'http://localhost:8080/api',
      idpApiUrl: 'http://localhost:8080/auth-api',
      //apiUrl: 'mock-api',
      recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      idpFrontendUrl: 'http://localhost:3001',
      supportedRegions: 'EU,US',
      hubspotTrackingEnabled: 'false',
    },
    production: {
      apiUrl: 'https://signup.keystrike.com/api',
      idpApiUrl: 'https://id.admin.keystrike.com/auth-api',
      recaptchaSiteKey: '6Lf-e5ApAAAAAP_1_TxrBcR6G-j89Lv-gKxZ13_1',
      idpFrontendUrl: 'https://id.admin.keystrike.com',
      supportedRegions: 'EU,US',
      hubspotTrackingEnabled: 'true',
    }
  }
  
  const currentEnvironment = process.env.NODE_ENV || 'development'
  
  if (!environments[currentEnvironment]) {
    throw new Error(`Unknown environment: ${currentEnvironment}`)
  }
  
  module.exports = environments[currentEnvironment]
  